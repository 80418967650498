import React, { Component } from "react";
import { reduxForm, Field } from "redux-form";
import Dropzone from "react-dropzone";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";
import asyncValidate from "../asyncValidate";
import validate from "../Validate";
import TextInput from "../../../../app/common/form/TextInput";
import AsyncTextInput from "../../../../app/common/form/TextInput";
import SelectInput from "../../../../app/common/form/SelectInput";

const componentConfig = {
  iconFiletypes: [".jpg", ".png", ".gif"],
  showFiletypeIcon: true,
  postUrl: "/uploadHandler",
};

class MerchantDetails extends Component {
  state = {
    files: [],
    fileName: "",
    omang: [],
    omangFileName: "",
    affidavit: [],
    affidavitFileName: "",
  };

  render() {
    const { handleSubmit } = this.props;

    let sellers = {};

    sellers =
      this.props.sellerId &&
      this.props.sellerId.map((seller) => {
        return {
          label: seller.sellerId,
          value: seller.sellerId,
        };
      });

    let merchantTypes = {};

    merchantTypes =
      this.props.merchantTypes &&
      this.props.merchantTypes.map((merchantType) => {
        return {
          label: merchantType.type,
          value: merchantType.type,
        };
      });

    return (
      <form onSubmit={handleSubmit}>
        <p>
          <strong>Merchant Details</strong>
        </p>
        <div>
          <div className={"row clearfix"}>
            <div className={"col-sm-6"}>
              <Field
                name="merchantName"
                component={AsyncTextInput}
                type="text"
                className={"form-control"}
                placeholder={
                  this.props.merchantName == undefined || null
                    ? "Merchant Name"
                    : this.props.merchantName
                }
                onChange={this.props.onMerchantName}
                required="required"
              />
            </div>

            <div className={"col-sm-6"}>
              <Field
                name="merchantType"
                options={merchantTypes}
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 0,
                  colors: {
                    ...theme.colors,
                    primary: "neutral0",
                  },
                })}
                component={SelectInput}
                placeholder={
                  this.props.merchantType == undefined || null
                    ? "Merchant Type"
                    : this.props.merchantType
                }
                // onChange={this.props.onmerchantType}
                className={"form-control"}
                required="true"
              />
            </div>
            {/*             <div className={"col-sm-4"}>
              <Field
                // label="Merchant Group"
                name="merchantGroup"
                component={TextInput}
                type="text"
                className={"form-control"}
                placeholder="Group"
                required="required"
              />
            </div> */}

            {/*             <div className={"col-sm-4"}>
              <Field
                name="merchantSellerId"
                options={sellers}
                theme={(theme) => ({
                  ...theme,
                  borderRadius: 0,
                  colors: {
                    ...theme.colors,
                    primary: "neutral0",
                  },
                })}
                component={SelectInput}
                placeholder="Select Seller Id..."
                className={"form-control"}
                required="true"
              />
            </div> */}
          </div>
          <div className={"row clearfix"}>
            <div className={"col-sm-6"}>
              <Field
                name="merchantId"
                component={AsyncTextInput}
                type="text"
                className={"form-control"}
                placeholder={
                  this.props.merchantId == undefined || null
                    ? "ID Number"
                    : this.props.merchantId
                }
                onChange={this.props.onMerchantId}
              />
              {this.props.invalidIdError ? (
                <p style={{ color: "red" }}>ID number is not valid</p>
              ) : null}
            </div>
            <div className={"col-sm-6"}>
              <Field
                name="merchantPass"
                component={AsyncTextInput}
                type="text"
                className={"form-control"}
                placeholder={
                  this.props.merchantPass == undefined || null
                    ? "Passport Number"
                    : this.props.merchantPass
                }
                onChange={this.props.onmerchantPass}
              />
            </div>
            {this.props.idPassError ? (
              <p style={{ color: "red" }}>
                Either ID Number or Passport Number must be provided
              </p>
            ) : null}
          </div>
          <br />

          <div className={"row clearfix"}>
            <div className={"col-lg-6"}>
              <p>
                <strong> Signed Merchant Registration Form</strong>
              </p>
              <div className={"card card-default"}>
                {this.props.files && this.props.files[0] ? (
                  <div className={"card-body no-scroll no-padding dropzone "}>
                    <p
                      style={{
                        lineHeight: "50px",
                        margin: 0,
                        textAlign: "center",
                      }}
                    >
                      {this.props.files[0].name}
                    </p>
                  </div>
                ) : (
                  <div className={"card-body no-scroll no-padding dropzone "}>
                    <Dropzone
                      config={componentConfig}
                      onDrop={this.props.onDrop}
                      multiple={false}
                    >
                      {({ getRootProps, getInputProps, isDragActive }) => {
                        return (
                          <div
                            {...getRootProps()}
                            style={{ textAlign: "center" }}
                          >
                            <input {...getInputProps()} />
                            {isDragActive ? (
                              <p style={{ lineHeight: "50px", margin: 0 }}>
                                Drop files here...
                              </p>
                            ) : (
                              <p style={{ lineHeight: "50px", margin: 0 }}>
                                Click to select files to upload.
                              </p>
                            )}
                          </div>
                        );
                      }}
                    </Dropzone>
                  </div>
                )}
              </div>
            </div>
            <div className={"col-lg-6"}>
              <p>
                <strong> Merchant Certified Omang Copy</strong>
              </p>
              <div className={"card card-default"}>
                {this.props.omang && this.props.omang[0] ? (
                  <div className={"card-body no-scroll no-padding dropzone "}>
                    <p
                      style={{
                        lineHeight: "50px",
                        margin: 0,
                        textAlign: "center",
                      }}
                    >
                      {this.props.omang[0].name}
                    </p>
                  </div>
                ) : (
                  <div className={"card-body no-scroll no-padding dropzone "}>
                    <Dropzone
                      config={componentConfig}
                      onDrop={this.props.onDrop1}
                      multiple={false}
                    >
                      {({ getRootProps, getInputProps, isDragActive }) => {
                        return (
                          <div
                            {...getRootProps()}
                            style={{ textAlign: "center" }}
                          >
                            <input {...getInputProps()} />
                            {isDragActive ? (
                              <p style={{ lineHeight: "50px", margin: 0 }}>
                                Drop files here...
                              </p>
                            ) : (
                              <p style={{ lineHeight: "50px", margin: 0 }}>
                                Click to select files to upload.
                              </p>
                            )}
                          </div>
                        );
                      }}
                    </Dropzone>
                  </div>
                )}
              </div>
            </div>
          </div>
          <br />
          <div className={"row clearfix"}>
            <div className={"col-lg-6"}>
              <p>
                <strong> Merchant Affidavit</strong>
              </p>
              <div className={"card card-default"}>
                {this.props.affidavit && this.props.affidavit[0] ? (
                  <div className={"card-body no-scroll no-padding dropzone "}>
                    <p
                      style={{
                        lineHeight: "50px",
                        margin: 0,
                        textAlign: "center",
                      }}
                    >
                      {this.props.affidavit[0].name}
                    </p>
                  </div>
                ) : (
                  <div className={"card-body no-scroll no-padding dropzone "}>
                    <Dropzone
                      config={componentConfig}
                      onDrop={this.props.onDrop2}
                      multiple={false}
                    >
                      {({ getRootProps, getInputProps, isDragActive }) => {
                        return (
                          <div
                            {...getRootProps()}
                            style={{ textAlign: "center" }}
                          >
                            <input {...getInputProps()} />
                            {isDragActive ? (
                              <p style={{ lineHeight: "50px", margin: 0 }}>
                                Drop files here...
                              </p>
                            ) : (
                              <p style={{ lineHeight: "50px", margin: 0 }}>
                                Click to select files to upload.
                              </p>
                            )}
                          </div>
                        );
                      }}
                    </Dropzone>
                  </div>
                )}
              </div>
            </div>
          </div>
          {this.props.attachementError ? (
            <p style={{ color: "red" }}>Attach all required files</p>
          ) : null}
        </div>
        <br />
        <p>
          <strong>Contact and Address</strong>
        </p>
        <div>
          <div className={"row clearfix"}>
            <div className={"col-sm-4"}>
              <Field
                // label="City"
                name="merchantCity"
                component={TextInput}
                type="text"
                className={"form-control input-sm"}
                placeholder={
                  this.props.merchantCity == undefined || null
                    ? "City"
                    : this.props.merchantCity
                }
                onChange={this.props.onmerchantCity}
                required="required"
                autoComplete="off"
              />
            </div>
            <div className={"col-sm-4"}>
              <Field
                // label="Suburb"
                name="merchantSuburb"
                component={TextInput}
                type="text"
                className={"form-control input-sm"}
                placeholder={
                  this.props.merchantSuburb == undefined || null
                    ? "Suburb / PO Box (Self Service)"
                    : this.props.merchantSuburb
                }
                onChange={this.props.onmerchantSuburb}
                required="required"
              />
            </div>
            <div className={"col-sm-4"}>
              <Field
                // label="Contact Name"
                name="merchant_contact_name"
                component={TextInput}
                type="text"
                className={"form-control input-sm"}
                placeholder={
                  this.props.merchant_contact_name == undefined || null
                    ? "Contact Person"
                    : this.props.merchant_contact_name
                }
                onChange={this.props.onmerchant_contact_name}
                required="required"
              />
            </div>
          </div>

          <div className={"row clearfix"}>
            <div className={"col-sm-4"}>
              <Field
                // label="Contact Email"
                name="merchant_contact_email"
                component={TextInput}
                type="text"
                className={"form-control input-sm"}
                placeholder={
                  this.props.merchant_contact_email == undefined || null
                    ? "Contact Email"
                    : this.props.merchant_contact_email
                }
                onChange={this.props.onmerchant_contact_email}
                required="required"
              />
            </div>
            <div className={"col-sm-4"}>
              <Field
                // label="Telephone Number"
                name="merchant_contact_telephone"
                component={TextInput}
                type="text"
                className={"form-control input-sm"}
                placeholder={
                  this.props.merchant_contact_telephone == undefined || null
                    ? "Telephone Number"
                    : this.props.merchant_contact_telephone
                }
                onChange={this.props.onmerchant_contact_telephone}
                required="required"
              />
            </div>
            <div className={"col-sm-4"}>
              <Field
                // label="Telephone Number"
                name="merchant_referral_code"
                component={TextInput}
                type="text"
                className={"form-control input-sm"}
                placeholder={
                  this.props.merchant_referral_code == undefined || null
                    ? "Referral number"
                    : this.props.merchant_referral_code
                }
                onChange={this.props.onmerchant_referral_code}
              />

              {this.props.validreferralcodeMsg ? (
                <p style={{ color: "red" }}>{this.props.merchant_name}</p>
              ) : null}

              {this.props.invalidreferralcodeError ? (
                <p style={{ color: "red" }}>Referral code is not valid</p>
              ) : null}
            </div>
          </div>
        </div>

        <br />
        <div
          className={
            "p-t-20 sm-padding-5 sm-m-b-20 sm-m-t-20 bg-white clearfix"
          }
        >
          <ul className={"pager wizard no-style"}>
            <li className="next">
              <button
                className={"btn btn-primary btn-cons pull-right"}
                type="submit"
                // onClick={handleNextClick}
              >
                <span>Next</span>
              </button>
            </li>
          </ul>
        </div>
      </form>
    );
  }
}

const query = (ownProps) => {
  return [
    {
      collection: "sellerId",
      orderBy: ["sellerId", "desc"],
    },
    {
      collection: "merchantTypes",
      orderBy: ["type", "desc"],
    },
  ];
};

const mapState = (state) => ({
  sellerId: state.firestore.ordered.sellerId,
  merchantTypes: state.firestore.ordered.merchantTypes,
});

export default compose(
  connect(mapState),
  reduxForm({
    form: "merchantForm",
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    asyncValidate,
    asyncBlurFields: ["merchantName"],
    validate,
  }),
  firestoreConnect(query)
)(MerchantDetails);
