import React, { useState, useEffect, Fragment } from "react";
import { Link } from "react-router-dom";
import format from "date-fns/format";

import classes from "./index.module.css";
import { formatMoney } from "../../../../app/common/util/helpers";
import { moneyFormat } from "../../../../app/common/util/moneyFormat";
import Detail from "./detail";

const index = (props) => {
  let { transaction, index } = props;

  let [expanded, setExpanded] = useState(false);
  let [revealMoreClasses, setRevealMoreClasses] = useState(null);
  let [MemberRowClasses, setMemberRowClasses] = useState(null);

  let handleClick = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    !expanded
      ? setRevealMoreClasses([classes.RevealMore])
      : setRevealMoreClasses([classes.RevealMore, classes.RevealMoreExpanded]);
    !expanded
      ? setMemberRowClasses([classes.MemberRow])
      : setMemberRowClasses([classes.MemberRow, classes.MemberRowExpanded]);
  }, [expanded]);

  const transactionDetail = (transaction) => {
    switch (transaction.transactionType) {
      case "Sale":
        return transaction.saleType === "Airtime"
          ? transaction.saleType + " - " + transaction.provider
          : transaction.saleType + " - " + transaction.transactionDetail;

        break;
      case "Deposit":
        return transaction.depositType === "Bank Deposit"
          ? transaction.bank + " - " + transaction.depositMethod
          : transaction.depositType;
        break;
      case "Reversal":
        return transaction.reversalType + " - " + transaction.reversalReference;
        break;
      case "Withdrawal":
        return (
          transaction.withdrawalType + " - " + transaction.withdrawalDetail
        );
        break;
      case "Commission":
        return transaction.commissionType + " - " + transaction.provider;
        break;
      case "Transfer":
        return (
          transaction.transferFromAccount +
          " - " +
          transaction.transferToAccount
        );
        break;
      case "DeviceContract":
        return transaction.transactionType + " - " + transaction.contract;
        break;
      default:
        return transaction.saleType + " - " + transaction.transactionDetail;
    }
  };

  return (
    <Fragment>
      {/* <tr className={classes.MemberRow} onClick={() => handleClick()}>
        <td>
          {format(
            transaction.transactionDateTime.toDate(),
            "YYYY-MM-DD  HH:mm:ss"
          )}
        </td>
        <td>{transaction.outletId}</td>

        <td>{transaction.meterNumber}</td>
        <td>{moneyFormat(transaction.transactionAmount)}</td>
        <td>{transaction.isRedeemed ? "Yes" : "No"}</td>
      </tr> */}
      {/* 
      <tr
                      key={index}
                      onClick={() => this.handleClick()}
                      className={
                        this.state.selectedRow === index
                          ? classes.SelectedTransaction
                          : classes.MerchantRow
                      }
                    > */}
      <tr className={classes.MemberRow} onClick={() => handleClick()}>
        <td>
          {format(
            transaction.transactionDateTime.toDate(),
            "YYYY-MM-DD HH:mm:ss"
          )}
        </td>
        <td>
          {transaction.transactionType === "Deposit"
            ? format(transaction.dateOfDeposit.toDate(), "YYYY-MM-DD HH:mm:ss")
            : transaction.dateOfDeposit}
        </td>
        <td>
          {transaction.outletId ? transaction.outletId : transaction.account}
        </td>
        <td>
          {transaction.transactionType === "DeviceContract"
            ? transaction.deviceContractTransactionType
            : transaction.transactionType}
        </td>
        <td>{transactionDetail(transaction)}</td>
        <td>{moneyFormat(transaction.transactionAmount)}</td>
        <td>
          {transaction.tat
            ? transaction.tat
            : (transaction.transactionDateTime.toDate() -
                transaction.dateCreated.toDate()) /
              1000}
        </td>
        <td>
          {transaction.vendorBalance
            ? moneyFormat(transaction.vendorBalance)
            : "N/A"}
        </td>
      </tr>

      {expanded ? (
        <tr className={classes.Expandabled}>
          <td colSpan="8">
            <Detail transaction={transaction} />
          </td>
        </tr>
      ) : null}
    </Fragment>
  );
};

export default index;
