import React, { Component, Fragment } from "react";
import { Router, Route, Switch } from "react-router-dom";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import MomentUtils from "@date-io/moment";
import Layout from "./Layout";
import PrepaidPlusRoute from "./PrepaidPlusRoute";
import PrivateRoute from "./PrivateRoute";
import MerchantRoute from "./MerchantRoute";

import MerchantList from "../../features/merchants/Merchants";
// import Merchants from "../../features/dashboard";

// import Merchants from "../../features/merchants/Merchants";
import Merchants from "../../features/dashboard";
// import Dashboard from "../../features/dashboard/Cachet";
import Merchant from "../../features/merchants/Merchant/Merchant";
// import MerchantReports from "../../features/reports";
// import DepositDashboard from "../../features/deposits";
import Financials from "../../features/financials";

import Users from "../../features/users/Users";
import WUCUsers from "../../features/wucUsers/Users";

import Login from "../../features/auth/Login/LoginForm";
// import Register from "../../features/auth/Register/RegisterForm";
import Outlet from "../../features/merchants/Merchant/MerchantProfile/Outlets/Outlet/Outlet";
import Inventory from "../../features/inventory/Inventory";
import { history } from "../common/util/history";
import Authorisation from "../../features/authorisation";
import Airtime from "../../features/airtime";
import Settings from "../../features/settings";
import MerchantReports from "../../features/reports/Merchant";
import CachetReports from "../../features/reports/Cachet/";
import GroupReports from "../../features/reports/Group/";
import MerchantItemisedReport from "../../features/reports/Merchant/Reports/ItemisedReport";
import MerchantCategoryReport from "../../features/reports/Merchant/Category";
import MerchantUserReport from "../../features/reports/Merchant/User";
import MerchantOutletReport from "../../features/reports/Merchant/Outlet";
import MerchantDateReport from "../../features/reports/Merchant/Date";
import MerchantGroupItemisedReport from "../../features/reports/Merchant/Group/Itemised/ItemisedReport";
import MerchantGroupCategoryReport from "../../features/reports/Merchant/Group/Category";
import MerchantGroupDateReport from "../../features/reports/Merchant/Group/Date";

import CachetItemisedReport from "../../features/reports/Cachet/Itemised/ItemisedReport";
import CachetCategoryReport from "../../features/reports/Cachet/Category";
import CachetDateReport from "../../features/reports/Cachet/Date";
import CachetLevyReport from "../../features/reports/Cachet/Levy";
import VendorRecon from "../../features/reports/Cachet/VendorRecon";
import BPCDailySales from "../../features/reports/Cachet/BPCDailySales";
import KazangDailySales from "../../features/reports/Cachet/KazangDailySales";
import OozeDailySales from "../../features/reports/Cachet/OozeDailySales";
import BPCReversals from "../../features/reports/Cachet/BPCReversals";
import ReversalsReport from "../../features/reports/Cachet/ReversalsReport";
import ChoppiesMonthlyReport from "../../features/reports/Cachet/ChoppiesMonthlyReport";
import SalesAndCommissionReport from "../../features/reports/Cachet/SalesAndCommissionReport";
import GroupSalesAndCommissionReport from "../../features/reports/Cachet/GroupSalesAndCommissionReport";
import MerchantContractDepositsReport from "../../features/reports/Cachet/MerchantContractDepositsReport";
import MerchantDeviceTransactionsReport from "../../features/reports/Cachet/DeviceTransactionsReport";

import ChoppiesSalesAndCommissionReport from "../../features/reports/Cachet/ChoppiesSalesAndCommissionReport";
import FinancialsReport from "../../features/reports/Cachet/FinancialsReport";

import CachetFinancials from "../../features/financials/Cachet/";
import Transactions from "../../features/transactions";
import MerchantTransactions from "../../features/reports/Merchant/transactions";
import MerchantTransactionsByDateRange from "../../features/reports/Merchant/transactionsbydaterange";

import CustomerITReport from "../../features/reports/Merchant/CustomerITReport";

import MerchantStatement from "../../features/reports/Merchant/Statement";

import WUC from "../../features/wuc";
import SMS from "../../features/sms";

import Competitions from "../../features/competitions";
import Deposits from "../../features/deposits";
import Recons from "../../features/recons";
import Helpdesk from "../../features/helpdesk";
import reportingPortal from "../../features/reportingPortal";
import Triggers from "../../features/triggers";
import PettyCash from "../../features/pettycash";
import MerchantActivityReport from "../../features/reports/Cachet/MerchantActivityReport";
import ReferralProgrammeReport from "../../features/reports/Cachet/ReferralProgrammeReport";

const defaultMaterialTheme = createMuiTheme({
  typography: {
    fontSize: 18,
  },
});

class App extends Component {
  render() {
    return (
      <Fragment>
        <ThemeProvider theme={defaultMaterialTheme}>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <Router history={history}>
              <Switch>
                <Route exact path="/" component={Login} />
                <Route
                  path="/(.+)"
                  render={() => (
                    <Layout>
                      <Switch>
                        {/* <PrivateRoute
                          path="/dashboard"
                          exact
                          component={Dashboard}
                        /> */}
                        <PrivateRoute
                          path="/merchants"
                          exact
                          component={Merchants}
                        />
                        <PrivateRoute
                          path="/merchantlist"
                          exact
                          component={MerchantList}
                        />
                        <MerchantRoute
                          path="/merchants/:id"
                          exact
                          component={Merchant}
                        />
                        <MerchantRoute
                          path="/merchants/:account/outlet/:outletId"
                          component={Outlet}
                        />
                        <MerchantRoute
                          path="/merchants/:account/financials"
                          component={Financials}
                        />
                        <MerchantRoute
                          path="/merchants/:account/reports"
                          exact
                          component={MerchantReports}
                        />
                        <MerchantRoute
                          path="/merchants/:account/reports/transactions"
                          component={MerchantTransactions}
                        />
                        <MerchantRoute
                          path="/merchants/:account/reports/transactionsByDate"
                          component={MerchantTransactionsByDateRange}
                        />
                        <MerchantRoute
                          path="/merchants/:account/reports/recon"
                          component={CustomerITReport}
                        />

                        <MerchantRoute
                          path="/merchants/:account/reports/statement"
                          component={MerchantStatement}
                        />

                        <MerchantRoute
                          path="/merchants/:account/reports/itemised"
                          component={MerchantItemisedReport}
                        />
                        <MerchantRoute
                          path="/merchants/:account/reports/category"
                          component={MerchantCategoryReport}
                        />
                        <MerchantRoute
                          path="/merchants/:account/reports/user"
                          component={MerchantUserReport}
                        />
                        <MerchantRoute
                          path="/merchants/:account/reports/outlet"
                          component={MerchantOutletReport}
                        />
                        <MerchantRoute
                          path="/merchants/:account/reports/date"
                          component={MerchantDateReport}
                        />
                        <MerchantRoute
                          exact
                          path="/merchants/:account/reports/group/category"
                          component={MerchantGroupCategoryReport}
                        />
                        <MerchantRoute
                          exact
                          path="/merchants/:account/reports/group/itemised"
                          component={MerchantGroupItemisedReport}
                        />
                        <MerchantRoute path="/wuc" exact component={WUC} />
                        <MerchantRoute path="/sms" exact component={SMS} />

                        <MerchantRoute
                          exact
                          path="/merchants/:account/reports/group/date"
                          component={MerchantGroupDateReport}
                        />
                        <PrepaidPlusRoute
                          path="/airtime"
                          exact
                          component={Airtime}
                        />
                        <PrepaidPlusRoute
                          path="/pettycash"
                          exact
                          component={PettyCash}
                        />
                        <PrepaidPlusRoute
                          path="/reports"
                          exact
                          component={CachetReports}
                        />
                        <PrepaidPlusRoute
                          exact
                          path="/reports/bpc"
                          component={BPCDailySales}
                        />
                        <PrepaidPlusRoute
                          exact
                          path="/reports/merchantactivityreport"
                          component={MerchantActivityReport}
                        />
                          <PrepaidPlusRoute
                          exact
                          path="/reports/referralProgrammeReport"
                          component={ReferralProgrammeReport}
                        />
                        <PrepaidPlusRoute
                          exact
                          path="/reports/referralProgrammeReport"
                          component={ReferralProgrammeReport}
                        />
                        <PrepaidPlusRoute
                          exact
                          path="/reports/kazang"
                          component={KazangDailySales}
                        />
                        <PrepaidPlusRoute
                          exact
                          path="/reports/ooze"
                          component={OozeDailySales}
                        />
                        <PrepaidPlusRoute
                          exact
                          path="/reports/bpcReversals"
                          component={BPCReversals}
                        />
                        <PrepaidPlusRoute
                          exact
                          path="/reports/reversals"
                          component={ReversalsReport}
                        />
                        <PrepaidPlusRoute
                          exact
                          path="/reports/choppiesMonthlyReport"
                          component={ChoppiesMonthlyReport}
                        />
                        <PrepaidPlusRoute
                          exact
                          path="/reports/salesAndCommissonReport"
                          component={SalesAndCommissionReport}
                        />
                        <PrepaidPlusRoute
                          exact
                          path="/reports/groupSalesAndCommissonReport"
                          component={GroupSalesAndCommissionReport}
                        />
                        <PrepaidPlusRoute
                          exact
                          path="/reports/merchantContractDepositsReport"
                          component={MerchantContractDepositsReport}
                        />
                        <PrepaidPlusRoute
                          exact
                          path="/reports/merchantDeviceTransactionsReport"
                          component={MerchantDeviceTransactionsReport}
                        />
                        <PrepaidPlusRoute
                          exact
                          path="/reports/choppiesSalesAndCommissonReport"
                          component={ChoppiesSalesAndCommissionReport}
                        />
                        <PrepaidPlusRoute
                          exact
                          path="/reports/financialsReport"
                          component={FinancialsReport}
                        />
                        <PrepaidPlusRoute
                          exact
                          path="/reports/recon"
                          component={VendorRecon}
                        />
                        <PrepaidPlusRoute
                          path="/deposits"
                          exact
                          component={Deposits}
                        />

                        <PrepaidPlusRoute
                          path="/recons"
                          exact
                          component={Recons}
                        />

                        <PrepaidPlusRoute
                          exact
                          path="/reports/itemised"
                          component={CachetItemisedReport}
                        />
                        <PrepaidPlusRoute
                          exact
                          path="/reports/category"
                          component={CachetCategoryReport}
                        />
                        <PrepaidPlusRoute
                          exact
                          path="/reports/date"
                          component={CachetDateReport}
                        />
                        <PrepaidPlusRoute
                          exact
                          path="/reports/levy"
                          component={CachetLevyReport}
                        />
                        <PrepaidPlusRoute
                          path="/transactions"
                          exact
                          component={Transactions}
                        />

                        <MerchantRoute
                          path="/competitions"
                          exact
                          component={Competitions}
                        />
                        <PrepaidPlusRoute
                          path="/financials"
                          exact
                          component={CachetFinancials}
                        />
                        <MerchantRoute
                          path="/groupreports"
                          exact
                          component={GroupReports}
                        />
                        <PrepaidPlusRoute path="/users" component={Users} />
                        <MerchantRoute path="/wucusers" component={WUCUsers} />

                        <PrepaidPlusRoute
                          path="/inventory"
                          component={Inventory}
                        />
                        <PrepaidPlusRoute
                          path="/authorisation"
                          component={Authorisation}
                        />
                        <PrepaidPlusRoute
                          path="/settings"
                          component={Settings}
                        />
                        <PrepaidPlusRoute
                          path="/triggers"
                          exact
                          component={Triggers}
                        />
                        <PrepaidPlusRoute
                          path="/helpdesk"
                          exact
                          component={Helpdesk}
                        />
                        <PrepaidPlusRoute
                          path="/reportingportal"
                          exact
                          component={reportingPortal}
                        />
                      </Switch>
                    </Layout>
                  )}
                />
              </Switch>
            </Router>
          </MuiPickersUtilsProvider>
        </ThemeProvider>
      </Fragment>
    );
  }
}

export default App;
