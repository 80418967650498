//import liraries
import React from "react";
import { connect } from "react-redux";
import { withFirebase } from "react-redux-firebase";

import classes from "./NavigationItems.module.css";
import NavigationItem from "./NavigationItem/NavigationItem";

// create a component
const navigationItems = (props) => {
  const { auth, profile } = props;
  const authenticated = auth.isLoaded && !auth.isEmpty;
  const userAccount = profile.account;
  const userRole = profile.role;
  const userType = profile.userType;

  // return authenticated &&
  //   userRole === "Administrator" &&
  //   userType === "PrepaidPlus" ? (
  //   <ul className={classes.NavigationItems}>
  //     <NavigationItem link="/merchants">Home</NavigationItem>
  //     <NavigationItem link="/merchantlist">Merchants</NavigationItem>
  //     <NavigationItem link="/authorisation">Authorisation</NavigationItem>
  //     <NavigationItem link="/inventory">Inventory</NavigationItem>
  //     <NavigationItem link="/users">Users</NavigationItem>
  //     <NavigationItem link="/airtime">Airtime</NavigationItem>
  //     <NavigationItem link="/financials">Financials</NavigationItem>{" "}
  //     <NavigationItem link="/deposits">Deposits</NavigationItem>
  //     <NavigationItem link="/transactions">Transactions</NavigationItem>
  //     <NavigationItem link="/reports">Reports</NavigationItem>
  //     <NavigationItem link="/competitions">Competitions</NavigationItem>
  //   </ul>
  // ) : authenticated &&
  //   (userRole === "Supervisor" || userRole === "Support") &&
  //   userType === "PrepaidPlus" ? (
  //   <ul className={classes.NavigationItems}>
  //     <NavigationItem link="/merchants">Home</NavigationItem>
  //     <NavigationItem link="/merchantlist">Merchants</NavigationItem>
  //     <NavigationItem link="/inventory">Inventory</NavigationItem>
  //     <NavigationItem link="/users">Users</NavigationItem>
  //     <NavigationItem link="/financials">Financials</NavigationItem>
  //     <NavigationItem link="/deposits">Deposits</NavigationItem>
  //     <NavigationItem link="/transactions">Transactions</NavigationItem>
  //     <NavigationItem link="/reports">Reports</NavigationItem>
  //     <NavigationItem link="/competitions">Competitions</NavigationItem>
  //   </ul>
  // ) : (
  //   <ul className={classes.NavigationItems}>
  //     <NavigationItem link="/merchants">Home</NavigationItem>
  //     {userAccount === "4258" &&
  //     (userRole === "Administrator" || userRole === "Group Administrator") ? (
  //       <NavigationItem link="/competitions">Competitions</NavigationItem>
  //     ) : null}
  //   </ul>
  // );

  return authenticated &&
    userRole === "Administrator" &&
    userType === "PrepaidPlus" ? (
    <ul className={classes.NavigationItems}>
      <NavigationItem link="/merchants">Home</NavigationItem>
      <NavigationItem link="/merchantlist">Merchants</NavigationItem>
      <NavigationItem link="/authorisation">Authorisation</NavigationItem>
      <NavigationItem link="/inventory">Inventory</NavigationItem>
      <NavigationItem link="/users">Users</NavigationItem>
      <NavigationItem link="/airtime">Airtime</NavigationItem>
      <NavigationItem link="/financials">Financials</NavigationItem>{" "}
      <NavigationItem link="/deposits">Deposits</NavigationItem>
      <NavigationItem link="/recons">Recons</NavigationItem>
      <NavigationItem link="/transactions">Transactions</NavigationItem>
      <NavigationItem link="/reports">Reports</NavigationItem>
      <NavigationItem link="/sms">SMS</NavigationItem>
      <NavigationItem link="/wuc">WUC</NavigationItem>
      <NavigationItem link="/wucUsers">WUC Users</NavigationItem>
      <NavigationItem link="/triggers">Triggers</NavigationItem>
      <NavigationItem link="/pettycash">PettyCash</NavigationItem>
      <NavigationItem link="/helpdesk">Helpdesk</NavigationItem>
      <NavigationItem link="/reportingportal">Reporting Portal</NavigationItem>
    </ul>
  ) : authenticated &&
    (userRole === "Supervisor" || userRole === "Support") &&
    userType === "PrepaidPlus" ? (
    <ul className={classes.NavigationItems}>
      <NavigationItem link="/merchants">Home</NavigationItem>
      <NavigationItem link="/merchantlist">Merchants</NavigationItem>
      <NavigationItem link="/inventory">Inventory</NavigationItem>
      <NavigationItem link="/users">Users</NavigationItem>
      <NavigationItem link="/financials">Financials</NavigationItem>
      <NavigationItem link="/deposits">Deposits</NavigationItem>
      <NavigationItem link="/recons">Recons</NavigationItem>
      <NavigationItem link="/transactions">Transactions</NavigationItem>
      <NavigationItem link="/reports">Reports</NavigationItem>
      <NavigationItem link="/sms">SMS</NavigationItem>
      <NavigationItem link="/wuc">WUC</NavigationItem>
      <NavigationItem link="/triggers">Triggers</NavigationItem>
      <NavigationItem link="/pettycash">PettyCash</NavigationItem>
      <NavigationItem link="/helpdesk">Helpdesk</NavigationItem>
      <NavigationItem link="/reportingportal">Reporting Portal</NavigationItem>
    </ul>
  ) : userType === "WUC" ? (
    <ul className={classes.NavigationItems}>
      <NavigationItem link="/wuc">WUC</NavigationItem>
      {userRole === "Administrator" ? (
        <NavigationItem link="/wucUsers">Users</NavigationItem>
      ) : null}
    </ul>
  ) : (
    <ul className={classes.NavigationItems}>
      <NavigationItem link="/merchants">Home</NavigationItem>
      {/* <NavigationItem link="/merchantTransactions">Transactions</NavigationItem> */}

      {userAccount === "4258" &&
      (userRole === "Administrator" || userRole === "Group Administrator") ? (
        <NavigationItem link="/competitions">Competitions</NavigationItem>
      ) : null}
    </ul>
  );
};

const mapStateToProps = (state) => ({
  auth: state.firebase.auth,
  profile: state.firebase.profile,
});
//make this component available to the app
// export default navigationItems;
export default withFirebase(connect(mapStateToProps)(navigationItems));
